/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import React from 'react'
import GlobalDailybreakContext from 'global/context/context'
import analytics from 'global/AnalyticsData'
import sessionStorageWrapper from 'util/SessionStorageWrapper'

const injectFacebookScript = () => {
  const script = document.createElement('script')
  script.async = true
  script.defer = true
  script.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v12.0'
  script.setAttribute('crossorigin', 'anonymous')
  script.setAttribute('nonce', 'FYZkWcw1')

  document.getElementsByTagName('body')[0].appendChild(script)
}

const fingerPrintUrl = 'https://dailybreak-scripts.s3.amazonaws.com/fv3.js'

const injectFingerprintJS = async () => {
  const script = document.createElement('script')
  script.async = true
  script.defer = true

  script.innerHTML = `
    (async () => {
      try {
        const FingerprintJS = await import("${fingerPrintUrl}");
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        window.fingerPrintResult = result;
      } catch (error) {
        console.error('Error loading FingerprintJS:', error);
      }
    })();`

  document.body.appendChild(script)
}

export const wrapRootElement = ({ element }) => {
  return <GlobalDailybreakContext>{element}</GlobalDailybreakContext>
}

const setCookie = function (name, value, days) {
  var expires = ''
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie =
    name +
    '=' +
    (value || '') +
    expires +
    '; path=/; domain=.dailybreak.com; secure: true; samesite: strict;'
}

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  // page_view
  // if we need richer data (likley) we'll need to move this code to a few different places
  // putting this in onRouteUpdate seemed to get called after component code (??)
  if (typeof window !== `undefined`) {
    sessionStorageWrapper.removeItem('utm_campaign')
    sessionStorageWrapper.removeItem('utm_content')
    sessionStorageWrapper.removeItem('utm_source')
    sessionStorageWrapper.removeItem('utm_medium')
    sessionStorageWrapper.removeItem('utm_term')
    sessionStorageWrapper.removeItem('transaction_id') // hasoffers

    let utm_campaign = null
    let utm_content = null
    let utm_source = null
    let utm_medium = null
    let utm_term = null
    let transaction_id = null

    //This was throwing errors. Nothing wrong with a little try/catch
    const tryToGetParams = name => {
      let returnedVal = {}
      try {
        returnedVal = JSON.parse(
          '{"' +
            decodeURI(name)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}',
        )
      } catch (e) {
        console.log('error parsing', name)
        returnedVal = {}
      }
      return returnedVal
    }
    var search = window.location.search.substring(1)
    if (search.length > 0) {
      let params = tryToGetParams(search)

      if ('utm_campaign' in params) {
        utm_campaign = params['utm_campaign']
      }

      if ('utm_content' in params) {
        utm_content = params['utm_content']
      }

      if ('utm_source' in params) {
        utm_source = params['utm_source']
      }

      if ('utm_medium' in params) {
        utm_medium = params['utm_medium']
      }

      if ('utm_term' in params) {
        utm_term = params['utm_term']
      }

      if ('transaction_id' in params) {
        transaction_id = params['transaction_id']
      }
    }

    // sessionStorageWrapper coerces values to string. So null gets stored as "null" :/
    if (utm_campaign) {
      sessionStorageWrapper.setItem('utm_campaign', utm_campaign)
      setCookie('utm_campaign', utm_campaign, 0)
    }

    if (utm_content) {
      sessionStorageWrapper.setItem('utm_content', utm_content)
      setCookie('utm_content', utm_content, 0)
    }

    if (utm_source) {
      sessionStorageWrapper.setItem('utm_source', utm_source)
      setCookie('utm_source', utm_source, 0)
    }

    if (utm_medium) {
      sessionStorageWrapper.setItem('utm_medium', utm_medium)
      setCookie('utm_medium', utm_medium, 0)
    }

    if (utm_term) {
      sessionStorageWrapper.setItem('utm_term', utm_term)
      setCookie('utm_term', utm_term, 0)
    }

    if (transaction_id) {
      sessionStorageWrapper.setItem('transaction_id', transaction_id)
      setCookie('transaction_id', transaction_id, 0)
    }
  }

  if (typeof window !== 'undefined') {
    // console.log("event: page_view")
    window.dataLayer.push({
      event: 'page_view',
    })

    analytics.setStep({})
    analytics.setBreak({})
  }
}

let injectedFacebookScript = false
var injectedFingerprintJS = false

export const onRouteUpdate = (args, pluginOptions = {}) => {
  if (!injectedFingerprintJS) {
    injectFingerprintJS()
    injectedFingerprintJS = true
  }

  if (document.querySelector('.fb-comments') !== null) {
    if (!injectedFacebookScript) {
      injectFacebookScript()
      injectedFacebookScript = true
    } else {
      if (window && window.FB) {
        window.FB.XFBML.parse()
      }
    }
  }

  //forcing consent for cookiebot
  if (window.Cookiebot) {
    if (window.Cookiebot.hasResponse === false) {
      window.Cookiebot.submitCustomConsent(true, true, true)
    }
  }
}

import React from 'react'
import { UserProvider } from './sub-contexts/userContext'
import { ThemeProvider } from './sub-contexts/themeContext'
import { ModalProvider } from './sub-contexts/modalContext'
import { ResizeProvider } from './sub-contexts/resize-context'
import { HeaderProvider } from './sub-contexts/headerObserverContext'

/*
    GlobalDailybreakContext represents the broader shared moving parts that the site needs, e.g.:
    - is this user a guest or are they logged in?
    - award the user points
    - a guest attempted to perform an action that requires an account - tell the site to pop some form of notifcation

    this this is global, try to keep it lean if possible

*/

const GlobalDailybreakContext = ({ children }) => {
  return (
    <ThemeProvider>
      <ModalProvider>
        <UserProvider>
          <ResizeProvider>
            <HeaderProvider>{children}</HeaderProvider>
          </ResizeProvider>
        </UserProvider>
      </ModalProvider>
    </ThemeProvider>
  )
}

export default GlobalDailybreakContext

/*

Wrapper around the browser's Session Storage api. When session storage isn't available, data is written to the `window`.

It's means to be a drop-in replacement.

instead of:
window.sessionStorage.getItem('some_key')

use:
import sessionStorageWrapper from 'util/SessionStorageWrapper'
sessionStorageWrapper.getItem('some_key')


inspired by:
https://github.com/gatsbyjs/gatsby/blob/fb55ff6b0f/packages/gatsby-react-router-scroll/src/scroll-handler.tsx


*/
const BREAKTIME_SESSION = `___btm_session`

class _SessionStorageWrapper {
  getItem(key) {
    try {
    
      const value = window.sessionStorage.getItem(key)
      return value === 'null' ? null : value // sessionStorage coerces null to "null". this fixes it.
    } catch (e) {
      if (
        typeof window != 'undefined' &&
        window[BREAKTIME_SESSION] &&
        window[BREAKTIME_SESSION][key]
      ) {
        return window[BREAKTIME_SESSION][key]
      }

      return null
    }
  }

  removeItem(key) {
    try {
      const value = window.sessionStorage.removeItem(key)
      return value === 'null' ? null : value // sessionStorage coerces null to "null". this fixes it.
    } catch (e) {

      if (
        typeof window != 'undefined' &&
        window[BREAKTIME_SESSION] &&
        window[BREAKTIME_SESSION][key]
      ) {
        return window[BREAKTIME_SESSION][key]
      }

      return null
    }
  }

  setItem(key, value) {
    // we leave JSON.stringify up to the caller
    // const storedValue = JSON.stringify(value)
    try {
      window.sessionStorage.setItem(key, value)
    } catch (e) {
      if (window && window[BREAKTIME_SESSION]) {
        window[BREAKTIME_SESSION][key] = value
      } else {
        window[BREAKTIME_SESSION] = {}
        window[BREAKTIME_SESSION][key] = value
      }
    }
  }
}

const sessionStorageWrapper = new _SessionStorageWrapper()

export default sessionStorageWrapper
